import React, { useEffect } from 'react';
import { ListWidget } from '../widget/list';
import { IMemoryListItem, ThingTypes, transformForList } from '../../model/ryve/Thing';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fetchNextPageSearchEntriesRequestAction } from '../../data/actions/search';
import { ISearchResult } from '../../model/search/SearchResult';
import { FramedContent } from '../framed-content';
import { INotification } from '../../model/notifications';
import { getLinkDocumentsAction, userprofileMemorylistRequestAction } from '../../data/actions/foundation';
import { setFlowDataAction } from '../../data/actions/flow';
import { Bx, Typo } from '@curry-group/mui-curcuma';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm'
import { Skeleton } from '@material-ui/lab';
import { faMessageBot } from '@fortawesome/pro-light-svg-icons';
import { ISearchRequestBody } from '../../model/search/SearchRequest';
import { useFilterState } from '../../data/sagas/filter';
// import { checkforDocIds, replaceDocId } from '../../helper';
import { checkforDocIds } from '../../helper';

export interface ISearchResultProps {
  result?: ISearchResult | null;
  error?: string;
  first?: boolean;
  working?: boolean;
  moreDataAvailable?: boolean;
  unreadNotifications?: Array<INotification>;
  fetchNextPageSearchEntries?: () => void;
  memoryIds?: Array<string>;
  itemMemoryClicked?: (item: IMemoryListItem, type: 'GET'|'POST'|'DELETE') => void;
  profileId?: string;
  shareClick?: (e:any) => void;
  searchObject?: ISearchRequestBody;
}

const useStyles = makeStyles(theme => ({
  markdown: {
    '& > *:first-child': {
      marginTop: 0
    }
  }
}));

export const SearchResults: React.FC<ISearchResultProps> = ({
  result,
  error,
  first,
  working,
  moreDataAvailable,
  unreadNotifications,
  fetchNextPageSearchEntries,
  memoryIds,
  itemMemoryClicked,
  profileId,
  shareClick,
  searchObject
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const botSearch = useSelector(state => state.filter?.botSearch);
  const botAnswer = useSelector(state => state.news?.botAnswer);
  // const linkedDocsDict = useSelector(state => state.foundation?.linkedDocsById);

  // const dispatcher = {
  //   getLinkDocuments: (docIds: Array<string>) => dispatch(getLinkDocumentsAction?.({ docIds }))
  // };

  const handleScroll = () => {
    if (window.innerHeight + document.documentElement.scrollTop + window.innerHeight * 1.2 <= document.documentElement.offsetHeight) return;
    if (!working && moreDataAvailable) fetchNextPageSearchEntries?.();
  }
  
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });
  
  // let linkedDocIds: Array<string> = [];
  // if (botAnswer) {
  //   linkedDocIds = checkforDocIds(botAnswer, linkedDocsDict);
  //   if (linkedDocIds.length > 0) {
  //     // console.log('linkedDocIds & Dict after fuggn it up...', linkedDocIds, linkedDocsDict);
  //     dispatcher.getLinkDocuments(linkedDocIds);
  //   }
  // }

  const showBotAnswer = botSearch && searchObject?.queryTerm;

  return (
    <FramedContent>
      {showBotAnswer && <Bx display="flex" mb={2} flexDirection={'row'} style={{border: '1px solid #000',borderRadius:16,background:'white',padding:'16px'}}>
        <Bx pr={2}>
          <Typo variant="h1" component="p">
            <FontAwesomeIcon icon={faMessageBot} />
          </Typo>
        </Bx>
        <Bx>
          {(!working && !botAnswer) && <Typo variant="body1" component="p" style={{width:'100%',marginTop:'8px'}}>
            Geben Sie oben einen Suchbegriff ein und schicken sie die Suche per Eingabe-Taste ab (ENTER).
            </Typo>}
          {(working && !botAnswer) && <Typo variant="body1" component="p" style={{width:'100%',marginTop:'8px'}}>
            <Skeleton animation="pulse" width="16px" style={{display:'inline-block'}} />
            &nbsp;
            <Skeleton animation="pulse" width="16px" style={{display:'inline-block'}} />
            &nbsp;
            <Skeleton animation="pulse" width="16px" style={{display:'inline-block'}} />
          </Typo>}
          {!!botAnswer && <Markdown className={clsx(classes.markdown)} remarkPlugins={[remarkGfm]} linkTarget="_blank">{(botAnswer ?? '')}</Markdown>}
        </Bx>
      </Bx>}
      <ListWidget
        working={first || working}
        items={transformForList(result?.hits?.hits.map(hit => {
          return {
            ...hit,
            badge: !!unreadNotifications?.find(notification => notification.content?.itemRef === hit._id),
            booked: hit._source?.type === ThingTypes.News
              ? (!!hit._source?.content.url ? memoryIds?.includes(hit._source?.content.url) : false)
              : memoryIds?.includes(hit._id),
            noShare: hit._source?.type === ThingTypes.OneToOne || ((hit._source?.type === ThingTypes.Group || hit._source?.type === ThingTypes.Project) && !hit._source?.content?.listed),
            profileId: memoryIds || hit._source?.type === ThingTypes.OneToOne ? profileId : undefined
          };
        }))}
        headline={result?.hits?.total ? `Suchergebnisse (${result.hits.total})` : 'Keine Suchergebnisse'}
        itemMemoClicked={itemMemoryClicked}
        shareClick={shareClick}
      />
    </FramedContent>
  );
};

export const ConnectedSearchContent = () => {
  const history = useHistory();
  const result = useSelector(state => state.search?.result);
  const error = useSelector(state => state.search?.error);
  const first = useSelector(state => state.search?.first);
  const working = useSelector(state => state.search?.working);
  const moreDataAvailable = useSelector(state => state.search?.moreDataAvailable);
  const notifications = useSelector(state => state.notifications?.items);
  const memoryItems = useSelector(state => state.foundation?.profile?.memoryList);
  const profileId = useSelector(state => state.foundation?.profile?.profileId);
  const { searchObject } = useFilterState();
  
  const dispatch = useDispatch();
  const dispatcher = {
    fetchNextPageSearchEntriesAction: () => dispatch(fetchNextPageSearchEntriesRequestAction?.({})),
    itemMemoryClicked: (item: IMemoryListItem, type: 'GET'|'POST'|'DELETE') => dispatch(userprofileMemorylistRequestAction({
      memoryItem: item,
      type: type
    })),
    shareClick: (detailItem) => {
      dispatch(setFlowDataAction({
        alias: 'share',
        flowData: {
          location: history.location,
          itemData: { sharedItem: detailItem }
        }
      }));
      history.push(history.location.pathname + '/flow' + history.location.search);
    }
  };

  const memoryIds = memoryItems?.map(m => m.typeId === ThingTypes.News ? (m.externalId ?? '') : m.elementId);

  return (
    <SearchResults
      result={result}
      error={error}
      first={first}
      working={working}
      moreDataAvailable={moreDataAvailable}
      unreadNotifications={notifications.filter(notification => !notification.content?.notificationRead)}
      fetchNextPageSearchEntries={dispatcher.fetchNextPageSearchEntriesAction}
      memoryIds={memoryIds}
      itemMemoryClicked={dispatcher.itemMemoryClicked}
      profileId={profileId}
      shareClick={dispatcher.shareClick}
      searchObject={searchObject}
    />
  );
};
