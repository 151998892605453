import { createReducer } from '@reduxjs/toolkit';
import { IProfile } from '../../../model/profile';
import { IContainerConfig, INavigationConfig } from '../../../model/configuration';
import {
  userDeauthAction,
  userLoginFailedAction,
  userLoginSuccessAction,
  userLogoutSuccessAction,
  userMtoLoginFailedAction,
  userMtoLoginSuccessAction
} from '../../actions/auth';
import {
  fetchStartupFailedAction,
  fetchStartupSuccessAction,
  getLinkDocumentsAction,
  setAppStateActiveConfigAction,
  setAppstateMobileAction,
  setAvatarAssetsAction,
  setLinkDocumentsAction,
  setNavbarOpenAction,
  setUpdateTermsAction,
  userprofileAllowExternalLinksErrorAction,
  userprofileAllowExternalLinksRequestAction,
  userprofileAllowExternalLinksSuccessAction,
  userprofileChangeDataVisibilityErrorAction,
  userprofileChangeDataVisibilityRequestAction,
  userprofileChangeDataVisibilitySuccessAction,
  userprofileChangeMessagingPermissionsErrorAction,
  userprofileChangeMessagingPermissionsRequestAction,
  userprofileChangeMessagingPermissionsSuccessAction,
  userprofileMemorylistErrorAction,
  userprofileMemorylistRequestAction,
  userprofileMemorylistSuccessAction,
} from '../../actions/foundation';
import { IDictionary } from '../../../helper';
import { IListUser } from '../../../model/search/SearchResult';

interface IFoundationState {
  auth: boolean;
  anon: boolean;
  authError: boolean;
  isMobile: boolean;
  navbarOpen?: boolean;
  profile?: IProfile | null;
  appconfig: IDictionary<IContainerConfig> | null;
  navigation?: INavigationConfig[];
  activeConfig?: IContainerConfig;
  chats?: string[];
  groups?: string[];
  updatetermsrules: boolean;
  welcomeversion: string;
  blacklist: string[];
  avatarsById?: IDictionary<IListUser>;
  linkedDocsById?: IDictionary<any>;
}

const initialState: IFoundationState = {
  auth: false,
  anon: true,
  authError: false,
  isMobile: false,
  appconfig: {},
  updatetermsrules: false,
  welcomeversion: '',
  blacklist: [],
  linkedDocsById: {},
};

const foundation = createReducer(initialState, builder =>
  builder
    .addCase(userMtoLoginSuccessAction, (state, action) => {
      return { ...state, auth: true, authError: false };
    })
    .addCase(userLoginSuccessAction, (state, action) => {
      return { ...state, auth: true, authError: false };
    })
    .addCase(userLoginFailedAction, (state, action) => {
      return { ...state, auth: false, authError: true };
    })
    .addCase(fetchStartupSuccessAction, (state, action) => {
      let fullName = '';
      if (action.payload?.profile) {
        fullName = ((action.payload?.profile?.title ?? '') + ' ' + (action.payload?.profile?.firstName ?? '') + ' ' + (action.payload?.profile?.lastName ?? '')).trim();
      }
      const oldAppConfig = action.payload.appconfig as any;
      const appconfig: any = Object.keys(oldAppConfig).reduce((current: any, next: any) => {
        current[next] = { ...oldAppConfig[next], alias: next };
        return current;
      }, {});
      return {
        ...state,
        auth: !action.payload.anon,
        authError: false,
        ...action.payload,
        appconfig,
        profile: {
          ...action.payload.profile,
          fullName,
        },
      };
    })
    .addCase(fetchStartupFailedAction, (state, action) => {
      return { ...state, auth: false, authError: false, profile: null, appconfig: null };
    })
    .addCase(userLogoutSuccessAction, (state, action) => {
      return { ...state, auth: false, authError: false, profile: null, appconfig: null };
    })
    .addCase(userDeauthAction, (state, action) => {
      return { ...state, auth: false, authError: false, profile: null, appconfig: null };
    })
    .addCase(userMtoLoginFailedAction, (state, action) => {
      return { ...state, auth: false, authError: false, profile: null, appconfig: null };
    })
    .addCase(setAppstateMobileAction, (state, action) => {
      return { ...state, isMobile: action.payload };
    })
    .addCase(setNavbarOpenAction, (state, action) => {
      return { ...state, navbarOpen: action.payload };
    })
    .addCase(setAppStateActiveConfigAction, (state, action) => {
      return { ...state, activeConfig: action.payload };
    })
    .addCase(setUpdateTermsAction, (state, action) => {
      return { ...state, updatetermsrules: false };
    })
    .addCase(userprofileAllowExternalLinksRequestAction, (state, action) => {
      return { ...state };
    })
    .addCase(userprofileAllowExternalLinksSuccessAction, (state, action) => {
      return {
        ...state,
        profile: {
          ...state.profile,
          externalLinksAllowed: !state.profile?.externalLinksAllowed || false,
          isAdmin: state.profile?.isAdmin || false,
          chatAllowed: state.profile?.chatAllowed || false,
        },
      };
    })
    .addCase(userprofileAllowExternalLinksErrorAction, (state, action) => {
      return {
        ...state,
        profile: {
          ...state.profile,
          externalLinksAllowed: false,
          isAdmin: state.profile?.isAdmin || false,
          chatAllowed: state.profile?.chatAllowed || false,
        },
      };
    })
    .addCase(userprofileChangeDataVisibilityRequestAction, (state, action) => {
      return { ...state };
    })
    .addCase(userprofileChangeDataVisibilitySuccessAction, (state, action) => {
      return {
        ...state,
        profile: {
          ...state.profile,
          dataVisibility: action.payload.dataVisibility || {},
          externalLinksAllowed: state.profile?.externalLinksAllowed || false,
          isAdmin: state.profile?.isAdmin || false,
          chatAllowed: state.profile?.chatAllowed || false,
        },
      };
    })
    .addCase(userprofileChangeDataVisibilityErrorAction, (state, action) => {
      return { ...state };
    })
    .addCase(userprofileChangeMessagingPermissionsRequestAction, (state, action) => {
      return { ...state };
    })
    .addCase(userprofileChangeMessagingPermissionsSuccessAction, (state, action) => {
      return {
        ...state,
        profile: {
          ...state.profile,
          messagingPermissions: action.payload.messagingPermissions || {},
          externalLinksAllowed: state.profile?.externalLinksAllowed || false,
          isAdmin: state.profile?.isAdmin || false,
          chatAllowed: state.profile?.chatAllowed || false,
        },
      };
    })
    .addCase(userprofileChangeMessagingPermissionsErrorAction, (state, action) => {
      return { ...state };
    })
    .addCase(setAvatarAssetsAction, (state, action) => {
      if (!action.payload.idAssetDict || Object.keys(action.payload.idAssetDict).length === 0) {
        return { ...state };
      }
      return { ...state, avatarsById: { ...state.avatarsById, ...action.payload.idAssetDict } };
    })
    .addCase(userprofileMemorylistRequestAction, (state, action) => {
      if (!action.payload?.memoryItem || !action.payload?.type) {
        return { ...state };
      }
    })
    .addCase(userprofileMemorylistSuccessAction, (state, action) => {
      if (!action.payload?.memoryList || !!action.payload.noRefresh) {
        return { ...state };
      }
      return {
        ...state,
        profile: {
          ...state.profile,
          externalLinksAllowed: state.profile?.externalLinksAllowed || false,
          isAdmin: state.profile?.isAdmin || false,
          chatAllowed: state.profile?.chatAllowed || false,
          memoryList: action.payload.memoryList,
        },
      };
    })
    .addCase(userprofileMemorylistErrorAction, (state, action) => {
      return { ...state };
    })
    .addCase(getLinkDocumentsAction, (state, action) => {
      return { ...state };
    })
    .addCase(setLinkDocumentsAction, (state, action) => {
      if (!action.payload.idLinkDict || Object.keys(action.payload.idLinkDict).length === 0) {
        return { ...state };
      }
      return { ...state, linkedDocsById: { ...state.linkedDocsById, ...action.payload.idLinkDict } };
    })
);
export default foundation;
