import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { cloneDeep } from 'lodash';
import { useHistory } from 'react-router-dom';
import Hidden from '@material-ui/core/Hidden';
import { AppFrameContent, AppFrameHeader } from '../frame';
import { resolveTimestampDateOnly } from '../../helper';
import { transformForList } from '../../model/ryve/Thing';
import { ListWidget } from '../widget/list';
import { FramedContent } from '../framed-content';
import { Btn, Bx, Typo, shorten } from '@curry-group/mui-curcuma';
import { fetchFirstPageNewsRequestAction, fetchNextPageNewsRequestAction, resetNewsAction } from '../../data/actions/news';
import { OpenExternalLinkDialog } from '../dialogs/open-external-link';
import { faMegaphone, faMessageBot } from '@fortawesome/pro-light-svg-icons';
import { setFlowDataAction } from '../../data/actions/flow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OpenNewsInfoDialog } from '../dialogs/open-news-info';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { useConfiguration } from '../../data/sagas/foundation';
import { setAppStateActiveConfigAction, userprofileMemorylistRequestAction } from '../../data/actions/foundation';
import { IMemoryListItem } from '../../model/ryve/Thing';
import { useFilterConfig, useFilterState } from '../../data/sagas/filter';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ConnectedDashboardHeader } from '../header/dashboard';
import { Skeleton } from '@material-ui/lab';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm'

const useStyles = makeStyles(theme => ({
  markdown: {
    '& > *:first-child': {
      marginTop: 0
    }
  }
}));

export const NewsDashboardComponent = () => {
  const classes = useStyles();
  useFilterConfig('news', false, false, false);
  const history = useHistory();
  const [newsInitial, setNewsInitial] = useState<boolean>(true);
  const [reTransform, setReTransform] = useState<string | undefined>('');
  const [news, setNews] = useState<any>([]);
  const [openExternalLinkDialog, setOpenExternalLinkDialog] = useState(false);
  const [openExternalLink, setOpenExternalLink] = useState('');
  const [openNewsInfoDialog, setOpenNewsInfoDialog] = useState(false);
  const { searchObject } = useFilterState();
  
  const config = useConfiguration('news');

  const memoryList = useSelector(state => state.foundation?.profile?.memoryList);
  const memoryIds = memoryList?.map(m => m.externalId);
  
  const externalLinksAllowed = useSelector(state => state.foundation?.profile?.externalLinksAllowed);
  const newsItems = useSelector(state => state.news?.items);
  const working = useSelector(state => state.news?.working);
  const moreDataAvailable = useSelector(state => state.news?.moreDataAvailable);
  const botSearch = useSelector(state => state.filter?.botSearch);
  const botAnswer = useSelector(state => state.news?.botAnswer);

  const dispatch = useDispatch();
  const dispatcher = {
    fetchFirstPageNewsAction: () => dispatch(fetchFirstPageNewsRequestAction?.()),
    fetchNextPageNewsAction: () => dispatch(fetchNextPageNewsRequestAction?.()),
    resetNewsAction: (loading: boolean) => dispatch(resetNewsAction?.({loading})),
  };

  const routeChangeFeedback = () => {
    history.push('/coop/groups-projects/groups/medtec-online-newsticker-feedbackforum');
  };
  const routeChangeProfile = () => {
    history.push('/user/profile?ref=/news');
  };

  const handleScroll = () => {
    if (window.innerHeight + document.documentElement.scrollTop + window.innerHeight
      <= document.documentElement.offsetHeight
    ) {
      return;
    }
    if (!working && moreDataAvailable) {
      dispatcher.fetchNextPageNewsAction();
    }
  };

  useEffect(() => {
    if (!working && newsInitial) {
      dispatch(fetchFirstPageNewsRequestAction?.());
    }
  }, [dispatch, working, newsInitial]);

  useEffect(() => {
    if (newsInitial) {
      setNewsInitial(false);
    }
  }, [newsInitial]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  useEffect(() => {
    config && dispatch(setAppStateActiveConfigAction(config));
  }, [config, dispatch]);

  useEffect(() => {
    if (newsItems) {
      const transformedItems: any[] = cloneDeep(newsItems)
        .map(news => {
          return {
            _id: news.content?.id,
            _score: news.content?.score,
            _source: {
              __original: news,
              type: news.type,
              content: news.content,
              created: dayjs(news.modifiedAt).unix() * 1000
            }
          };
        });
      const memoryClickedId = reTransform?.split('_|_|_')[0];
      setNews(
        transformForList(transformedItems).map(news => {
          const pl = news.__original?.content?.permissionLevel;
          let bodyText = '';
          switch(pl) {
            case 1:
              bodyText = news.__original?.content?.description ? shorten(news.__original?.content?.description, 500) : shorten(news.__original?.content?.body, 500);
              break;
            case 2:
              bodyText = news.__original?.content?.description ? shorten(news.__original?.content?.description, 500) : '';
              break;
            case 3:
            case 4:
            default:
              break;
          }
          return {
            ...news,
            type: resolveTimestampDateOnly(news.__original?.content?.pubDate),
            title: news.title,
            body: bodyText,
            to: news.__original?.content?.url,
            source: news.__original?.content?.sourcePreferredLabel,
            target: '_blank',
            categories: !!news.__original?.content?.categories ? news.__original?.content?.categories?.split(',') : undefined,
            itemTemplate: 'news',
            shareClick: () => {
              dispatch(
                setFlowDataAction({
                  alias: 'share',
                  flowData: {
                    location: history.location,
                    itemData: { sharedItem: news.__original }
                  }
                })
              );
              history.push(history.location.pathname + '/flow' + history.location.search);
            },
            booked: (!!memoryIds && !!news.__original?.content?.url
              ? memoryIds.includes(news.__original?.content?.url)
                ? !!memoryClickedId
                  ? news.__original?.content?.url === memoryClickedId ? false : true
                  : true
                : !!memoryClickedId
                  ? news.__original?.content?.url === memoryClickedId
                  : false
              : false
            ),
            selected: externalLinksAllowed
              ? undefined
              : () => {
                setOpenExternalLink(news.__original?.content?.url);
                setOpenExternalLinkDialog(true);
                return false;
              }
          }})
      );
    }
  }, [newsItems, externalLinksAllowed, reTransform]);

  const showBotAnswer = botSearch && searchObject?.queryTerm;
  return (
    <>
      <AppFrameHeader>
        <ConnectedDashboardHeader
          placeholder={'Newsticker durchsuchen'}
          alias={'news'}
        />
      </AppFrameHeader>
      <AppFrameContent>
        <FramedContent>
          {showBotAnswer && <Bx display="flex" mb={2} flexDirection={'row'} style={{border: '1px solid #000',borderRadius:16,background:'white',padding:'16px'}}>
            <Bx pr={2}>
              <Typo variant="h1" component="p">
                <FontAwesomeIcon icon={faMessageBot} />
              </Typo>
            </Bx>
            <Bx>
              {(!working && !botAnswer) && <Typo variant="body1" component="p" style={{width:'100%',marginTop:'8px'}}>
                Geben Sie oben einen Suchbegriff ein und schicken sie die Suche per Eingabe-Taste ab (ENTER).
                </Typo>}
              {(working && !botAnswer) && <Typo variant="body1" component="p" style={{width:'100%',marginTop:'8px'}}>
                <Skeleton animation="pulse" width="16px" style={{display:'inline-block'}} />
                &nbsp;
                <Skeleton animation="pulse" width="16px" style={{display:'inline-block'}} />
                &nbsp;
                <Skeleton animation="pulse" width="16px" style={{display:'inline-block'}} />
              </Typo>}
              {!!botAnswer && <Markdown className={clsx(classes.markdown)} remarkPlugins={[remarkGfm]} linkTarget="_blank">{(botAnswer ?? '')}</Markdown>}
            </Bx>
          </Bx>}
          <ListWidget
            headline={
              <Bx display={'flex'}>
                <Typo variant="h3" component="h3">
                  {!!searchObject?.queryTerm && !botSearch
                    ? `Suchergebnisse (${news?.length || 0})`
                    : 'Aktuelle Meldungen zu Ihren Interessen'}
                </Typo>
                &nbsp;
                {!searchObject?.queryTerm &&
                <Typo variant="h3" component="span">
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    onClick={() => {setOpenNewsInfoDialog(true);}}
                    color="white"
                    style={{backgroundColor:'#004B76', borderRadius:'50%', cursor:'pointer', fontSize:'1.8rem'}}
                  />
                </Typo>}
              </Bx>
            }
            noWorkingHeadline={true}
            endAdornment={
              <Btn onClick={routeChangeFeedback} variant="text" size="large" endIcon={<FontAwesomeIcon icon={faMegaphone} />}>
                <Hidden mdUp>{'Feedback' || 'Alle'}</Hidden>
                <Hidden smDown>{'Feedback zum Newsticker' || 'Alle anzeigen'}</Hidden>
              </Btn>
            }   
            items={news}
            working={working}
            divider={'none'}
            listType={'news'}
            itemMemoClicked={(item: IMemoryListItem, type: 'GET' | 'POST' | 'DELETE', seed?: string) => {
              dispatch(userprofileMemorylistRequestAction({ memoryItem: item, type }));
              setReTransform(item.externalId + '_|_|_' + seed);
            }}
          />
        </FramedContent>
        <OpenExternalLinkDialog
          open={openExternalLinkDialog}
          headerText={'Sie verlassen Medtec Online'}
          noteToUserText={'Bitte beachten Sie, dass dieser Link eine unabhängige Website öffnet, für deren Inhalt und Betrieb wir nicht verantwortlich sind; die Abfrage erfolgt direkt vom Server des Dritten. Für die Bereitstellung, Datenverarbeitung und den Inhalt ist der Dritte verantwortlich. Diese Meldung wird einmalig angezeigt, ihr Inhalt gilt auch für zukünftige Abrufe von Drittwebseiten.'}
          labelText={'Weiter zur externen Seite'}
          link={openExternalLink}
          onCanceled={() => {setOpenExternalLinkDialog(false);}}
        />
        <OpenNewsInfoDialog
          open={openNewsInfoDialog}
          headerText={'Interessen Ihres Profils'}
          noteToUserText={'Pflegen Sie die Interessen in Ihrem Profil, um personalisierte News angezeigt zu bekommen.'}
          maturityText={'Eine News bleibt 30 Tage im Newsticker angezeigt.'}
          labelText={'Zum Profil'}
          onClick={routeChangeProfile}
          onCanceled={() => {setOpenNewsInfoDialog(false);}}
        />
      </AppFrameContent>
    </>
  );
};
