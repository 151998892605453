import { createReducer } from '@reduxjs/toolkit';
import { INews } from '../../../model/news';
import {
  botResponseChunkAction,
  botResponseEndAction,
  fetchFirstPageNewsErrorAction,
  fetchFirstPageNewsRequestAction,
  fetchFirstPageNewsSuccessAction,
  fetchNextPageNewsErrorAction,
  fetchNextPageNewsRequestAction,
  fetchNextPageNewsSuccessAction,
  resetNewsAction,
  setNewsAllConcepts,
  setNewsWorking,
} from '../../actions/news';

const DEFAULT_PAGE_SIZE = 20;

export interface INewsState {
  items: INews[];
  first: boolean;
  filterChanged: boolean;
  working: boolean;
  moreDataAvailable: boolean;
  querySkip: number;
  queryPageSize: number;
  error: string;
  allConcepts: boolean;
  botAnswer?: string;
  botAnswerDict?: any;
}
const initialState: INewsState = {
  items: [],
  first: true,
  filterChanged: false,
  working: false,
  moreDataAvailable: true,
  querySkip: 0,
  queryPageSize: DEFAULT_PAGE_SIZE,
  error: '',
  allConcepts: false,
  botAnswer: '',
  botAnswerDict: {}
};

export const news = createReducer(initialState, builder =>
  builder
    .addCase(fetchFirstPageNewsRequestAction, (state, action) => {
      return {
        ...state,
        filterChanged: false,
        working: true,
        error: '',
        querySkip: 0,
        queryPageSize: DEFAULT_PAGE_SIZE,
        moreDataAvailable: false,
      };
    })
    .addCase(fetchFirstPageNewsSuccessAction, (state, action) => {
      let items = action.payload.results || [];
      let moreDataAvailable = action.payload.moreDataAvailable || false;
      if (action.payload.botAnswer) {
        items = state.items;
      }
      return {
        ...state,
        first: false,
        filterChanged: false,
        items: items,
        error: '',
        querySkip: items.length,
        moreDataAvailable,
        botAnswer: action.payload.botAnswer,
      };
    })
    .addCase(fetchFirstPageNewsErrorAction, (state, action) => {
      return {
        ...state,
        filterChanged: false,
        working: false,
        items: [],
        error: action.payload.message,
        querySkip: 0,
        queryPageSize: DEFAULT_PAGE_SIZE,
        moreDataAvailable: false,
        botAnswer: '',
      };
    })
    .addCase(fetchNextPageNewsRequestAction, (state, action) => {
      return {
        ...state,
        filterChanged: false,
        working: true,
        moreDataAvailable: false,
      };
    })
    .addCase(fetchNextPageNewsSuccessAction, (state, action) => {
      let items = action.payload.results || [];
      let moreDataAvailable = action.payload.moreDataAvailable || false;
      if (action.payload.botAnswer) {
        items = state.items;
      }
      return {
        ...state,
        first: false,
        items: [...(state?.items || []), ...items],
        querySkip: state.querySkip + items.length,
        moreDataAvailable,
        botAnswer: action.payload.botAnswer,
      };
    })
    .addCase(fetchNextPageNewsErrorAction, (state, action) => {
      return {
        ...state,
        filterChanged: false,
        working: false,
        error: action.payload.message,
        querySkip: 0,
        queryPageSize: DEFAULT_PAGE_SIZE,
        moreDataAvailable: false,
      };
    })
    .addCase(resetNewsAction, (state, action) => {
      return {
        ...state,
        items: [],
        first: true,
        filterChanged: false,
        working: action.payload.loading,
        moreDataAvailable: true,
        querySkip: 0,
        queryPageSize: DEFAULT_PAGE_SIZE,
        error: '',
      };
    })
    .addCase(setNewsAllConcepts, (state, action) => {
      return {
        ...state,
        allConcepts: state?.allConcepts === false ? true : false,
      };
    })
    .addCase(setNewsWorking, (state, action) => {
      return {
        ...state,
        working: action.payload.working,
      };
    })
    .addCase(botResponseChunkAction, (state, action) => {
      const chunkLength = Object.keys(state.botAnswerDict).length + 1;
      const unsortedChunks = { ...state.botAnswerDict, [action.payload.timestamp]: action.payload.chunk };
      if ((chunkLength % 10) !== 0) {
        return { ...state, botAnswerDict: unsortedChunks };
      }
      const sortedKeys = Object.keys(unsortedChunks).sort();
      const sortedChunks = sortedKeys.reduce((acc, key) => {
        acc[key] = unsortedChunks[key];
        return acc;
      }, {});
      return {
        ...state,
        botAnswer: Object.values(sortedChunks).join(''),
        botAnswerDict: sortedChunks,
      };
    })
    .addCase(botResponseEndAction, (state, action) => {
      return {
        ...state,
        botAnswer: action.payload.response,
        botAnswerDict: { },
      };
    })
);
